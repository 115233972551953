"use strict";

angular.module("informaApp")
    .service("BiomedtrackerUrlBuilder", ["ConstantsSvc", function (ConstantsSvc) {
        function build(configUrl, value) {
            return ConstantsSvc.biomedtracker.baseUrl + configUrl.url + "?" + configUrl.paramName + "=" + value;
        }

        return {
            buildIndicationUrl: function (id) {
                return build(ConstantsSvc.biomedtracker.others.indication, id);
            },
            buildCompanyUrl: function (id) {
                return build(ConstantsSvc.biomedtracker.others.company, id);
            },
            buildDrugUrl: function (id) {
                return build(ConstantsSvc.biomedtracker.others.drug, id);
            },
            buildTargetUrl: function (id) {
                return build(ConstantsSvc.biomedtracker.others.target, id);
            },
            buildEventHistoryUrl: function (id) {
                return build(ConstantsSvc.biomedtracker.others.eventHistory, id);
            }
        }
    }]);